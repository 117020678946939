@import '../base.scss';

.Settings {
  @include app-column;

  color: var(--text-black);
  font-weight: var(--font-weight-light);
  line-height: 22px;

  b {
    font-weight: var(--font-weight-default);
  }

  &__row {
    display: flex;
    align-items: center;
    padding: 26px 14px;
    border-bottom: var(--border-default);

    &--reduced-padding {
      padding: 14px;
    }

    &__spacer {
      flex-grow: 10;
    }

    &__right {
      text-align: right;
      max-width: 460px;
    }
  }

  &__link {
    display: inline-block;
    margin-top: 6px;
    font-weight: var(--font-weight-bold);
    cursor: pointer;

    &--warning {
      color: var(--red);
    }
  }

  &__photo {
    width: 56px;
    height: 56px;
    border-radius: 28px;
    overflow: hidden;

    &--default {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--text-light-light-light-gray);
      color: var(--text-light-gray);
    }
  }
}