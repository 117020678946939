@import '../base.scss';

.Select { 
  box-sizing: border-box;

  .Dropdown-control {
    border: none;
    outline: none;
    padding: 9px;
    padding-right: 25px;
    padding-left: 11px;
    text-align: right;
    background-color: var(--background-color);
    color: var(--text-black);
    cursor: pointer;

    &:hover {
      box-shadow: none;
    }
  }

  &__dropdown-indicator {
    position: absolute;
    right: 8px;
    top: 14px;
  }

  .Dropdown-menu {
    background-color: var(--background-color);
    border: var(--border-default);
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    padding: 5px 0;

    .Dropdown-option {
      line-height: 16px;
      padding: 8px 12px;
      color: var(--text-black);

      &.is-selected {
        background-color: transparent;
      }

      &:hover {
        background-color: var(--text-light-light-light-gray);
      }
    }
  }
}
