@import '../base.scss';

.Tags {
  @include app-column;

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 18px;

    > * {
      box-sizing: border-box;
    }
  }

  &__spacer {
    flex-grow: 10;
  }
}