@import url('https://fonts.googleapis.com/css?family=Raleway:300,600,700');
@import url('https://rsms.me/inter/inter.css');

//
//  Variables
//
$media-breakpoint-4: 1440px;
$media-breakpoint-3: 1200px;
$media-breakpoint-2: 1024px;
$media-breakpoint-1: 768px;
$media-breakpoint-0: 414px;

html {
  --font-family-default: 'Inter', sans-serif;
  --font-family-brand: 'Raleway', sans-serif;

  --font-size-extra-large: 24px;
  --font-size-large: 18px;
  --font-size-medium: 16px;
  --font-size-default: 14px;
  --font-size-small: 12px;

  --font-weight-bold: 700;
  --font-weight-medium: 600;
  --font-weight-default: 400;
  --font-weight-light: 300;

  --app-navbar-height: 83px;
  --brand-navbar-height: 119px;
  --banner-navbar-height: 108px;
  --brand-footer-height: 166px;
  --navbar-height-mobile: 59px;
  --footer-height-mobile: 100px;

  --dark-orange: #D77925;
  --orange: #F2994A;
  --light-orange: #FEC18B;
  --lighter-orange: #ffeee0;
  --light-light-orange: #FFFAF6;
  --black: black;

  --almost-black: #1E2128;
  --dark-gray: #5F5959;
  --gray: #828282;
  --light-gray: #BDBDBD;
  --light-light-gray: #E0E0E0;
  --light-light-light-gray: #F6F6F6;
  --white: white;
  --dark-red: #D63636;
  --red: #EB5757;

  --border-color: #F2F2F2;
  --input-border-color: var(--light-light-gray);

  --border-default: 1px solid var(--border-color);
  --input-border-default: 1px solid var(--input-border-color);

  --background-color: var(--white);
  --background-color-opposite: var(--almost-black);
  --background-alt-color: var(--light-light-orange);
  --background-strong-alt-color: var(--lighter-orange);

  --text-black: var(--black);

  --text-dark-gray: var(--dark-gray); // 
  --text-gray: var(--gray);
  --text-light-gray: var(--light-gray);
  --text-light-light-gray: var(--light-light-gray);
  --text-light-light-light-gray: var(--light-light-light-gray);
  --text-white: var(--white);

  &.dark-mode-enabled {
    @media (prefers-color-scheme: dark) {
      --border-color: #383D48;
      --input-border-color: var(--dark-gray);
  
      --background-color: var(--almost-black);
      --background-color-opposite: var(--white);
      --background-alt-color: #27272e;
      --background-strong-alt-color: #383841;
  
      --text-black: var(--white);
      --text-dark-gray: var(--light-light-gray);
      --text-gray: var(--gray);
      --text-light-gray: var(--dark-gray);
      --text-light-light-gray: var(--dark-gray);
      --text-light-light-light-gray: #333333;
      --text-white: #101010;
    }
  }
}

//
//  Root styling
//
:root {
  font-family: var(--font-family-default);
  font-size: var(--font-size-default);
  font-weight: var(--font-weight-default);
  color: var(--text-black);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin hover-border { 
  @media (hover: hover) {
    transition-property: border-radius, box-shadow;
    transition-duration: 0.15s;
    transition-timing-function: linear;
    box-shadow: 0 0 0 4px rgba(242, 242, 242, 0);
    &:hover, &--hover-border { 
      border-radius: 1px;
      box-shadow: 0 0 0 9px var(--border-color);
    }
  }
};

@mixin hover-border-small {
  @media (hover: hover) {
    transition-property: border-radius, box-shadow;
    transition-duration: 0.15s;
    transition-timing-function: linear;
    box-shadow: 0 0 0 1px rgba(242, 242, 242, 0);
    &:hover, &--hover-border-small { 
      border-radius: 1px;
      box-shadow: 0 0 0 3px var(--border-color);
    }
  }
}

html, 
body {
  height: 100%;
  background-color: var(--background-color);
}

#root {
  height: 100%;
}

*:focus {
  outline: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

@mixin input {
  outline: none;
  box-shadow: none;
  border: var(--input-border-default);
  box-sizing: border-box;
  border-radius: 2px;
  padding: 10px 13px;
  color: var(--text-black) !important;
  caret-color: var(--text-black);
  background-color: transparent;

  &::placeholder {
    color: var(--text-gray);
    padding-top: 1px;
  }
}
input { @include input; }

.ReactModal__Body--open {
  overflow: hidden;
}

.btn {
  display: inline-block;
  font-family: var(--font-family-default);
  border: none;
  padding: 9px 22px;
  background-color: var(--orange);
  border-radius: 3px;
  color: var(--text-white);
  font-style: normal;
  font-weight: var(--font-weight-medium);
  font-size: var(--font-size-default);
  text-align: center;
  cursor: pointer;
  box-sizing: border-box;
}

.btn:hover, 
.btn:focus,
.btn:active, 
.btn.active, 
.open .btn.dropdown-toggle { 
  color: var(--text-white);
  background-color: var(--orange); 
  border-color: var(--orange); 
}

.btn:disabled,
.btn:disabled:hover,
.btn.disabled {
  opacity: 0.5;
  cursor: default;
}

.btn.warning {
  background-color: var(--red);
}

.btn.secondary {
  color: var(--text-black);
  font-weight: 500;
  background-color: transparent;
  border: var(--input-border-default);
}

.btn.secondary.warning {
  color: var(--red);
}

.btn.medium {
  padding: 10px 24px;
  border-radius: 4px;
}

.btn.large {
  font-size: var(--font-size-medium);
  letter-spacing: 0.217143px;
  line-height: 23px;
  border: var(--input-border-default);
  border-radius: 6px;
  padding: 12px 30px;
  min-width: 230px;

  b {
    font-weight: var(--font-weight-medium) !important;
  }

  @media only screen and (max-width: $media-breakpoint-1) {
    font-size: var(--font-size-default) !important;
    padding: 9px 22px !important;
  }
}

.btn.icon {
  display: inline-flex;
  align-content: center;
  align-items: center;
  justify-content: center;

  > svg {
    margin-top: -2px;
    padding-right: 18px;
  }

  > svg.apple {
    margin-top: -4px;
    color: var(--text-black);
  }

  @media only screen and (max-width: $media-breakpoint-1) {
    > svg {
      transform: scale(0.9);
      margin-top: 0 !important;
      padding-right: 14px !important;
    }

    > svg.apple {
      margin-top: -2px !important;
    }
  }
}

.svg-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: color 0.15s linear;
  color: var(--text-light-light-gray);
  &:hover { color: var(--text-black); }
}

// For components that use a single central column for content in <App />

@mixin app-column {
  padding: 14px 60px 60px 60px;
  max-width: 1438px;
  margin: 0 auto;

  @media only screen and (max-width: 1563px) {
    max-width: 1072px;
  }

  @media only screen and (max-width: 1193px) {
    max-width: 704px;
  }

  @media only screen and (max-width: $media-breakpoint-1) {
    padding: 16px;
  }
}

// Controls

.controls {
  display: flex;
  align-content: center;
  > div > .control-btn {
    margin-right: 2px;
  }
  > div:last-child > .control-btn {
    margin-right: 0;
  }

  &--reverse {
    flex-direction: row-reverse;
  }
}

.control-btn {
  display: flex;
  align-items: center;
  justify-content: center;

  $diameter: 31px;
  height: $diameter;
  width: $diameter;
  border-radius: $diameter / 2;
  overflow: hidden;

  cursor: pointer;

  stroke-width: 1.5px;
  
  transition-property: color, background-color;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  color: var(--text-light-light-gray);
  background-color: transparent;
  &:hover { 
    color: var(--background-color); 
    background-color: var(--orange);
  }
}

// Brand pages

@mixin brand {
  color: var(--text-black);

  h1 {
    font-weight: var(--font-weight-bold);
    font-size: 64px;
    line-height: 120%;
    margin-top: 0;
    margin-bottom: 32px;
    margin-left: -4px;
  }

  h2 {
    font-weight: var(--font-weight-bold);
    font-size: 32px;
    line-height: 120%;
    margin-top: 0;
    margin-bottom: 22px;
  }

  h3 {
    font-weight: var(--font-weight-bold);
    font-size: 22px;
    line-height: 120%;
  }

  p {
    font-weight: var(--font-weight-light);
    font-size: 18px;
    line-height: 170%;
    margin-top: 0;
    margin-bottom: 22px;

    a {
      font-weight: var(--font-weight-medium);
    }
  }

  @media only screen and (max-width: $media-breakpoint-3) {
    h1 { font-size: 52px; }
  }

  @media only screen and (max-width: $media-breakpoint-2) {
    h1 { font-size: 42px; }
  }

  @media only screen and (max-width: $media-breakpoint-1) {
    h1 { font-size: 36px; }
    h2 { font-size: 18px; }
    h3 { font-size: 16px; }
    p { font-size: 14px; }
  }
}

// Dialogs

@mixin dialogs {
  &__row {
    display: flex;
    margin-top: 8px;
  }
  
  &__spacer {
    flex-grow: 10;
  }
  
  &__header {
    color: var(--text-gray);
    font-size: var(--font-size-medium);
    font-weight: var(--font-weight-medium);
    margin-top: 24px;
    margin-bottom: 8px;
  }
  
  &__header--warning {
    color: var(--red);
  }
  
  &__header:first-child {
    margin-top: 0;
  }
  
  &__text {
    color: var(--text-black);
    font-weight: var(--font-weight-light);
    line-height: 22px;
    overflow: hidden;
  
    > em {
      font-style: normal;
      font-weight: var(--font-weight-medium);
    }

    > a {
      font-weight: 500;
    }
  }

  &__link {
    color: var(--orange);
    font-weight: var(--font-weight-light);
    line-height: 22px;
    cursor: pointer;
  }
  
  &__subtext {
    color: var(--text-gray);
    font-weight: var(--font-weight-default);
    margin-top: 8px;
    margin-bottom: 8px;
  }
  
  &__input {
    flex-grow: 10;
    &:disabled { color: #000 !important; }
  }
  
  &__button {
    display: inline;
    margin-left: 8px;
  }
  
  &__buttons {
    margin-top: 26px;
  }
}