@import '../base.scss';

.LandingPage {
  @include brand;

  --margin: 120px;
  --section-gap: var(--margin);
  --section-padding: 177px;

  @media only screen and (max-width: $media-breakpoint-4) {
    --margin: 74px;
    --section-gap: var(--margin);
    --section-padding: 137px;
  }

  @media only screen and (max-width: $media-breakpoint-3) {
    --margin: 74px;
    --section-gap: calc(var(--margin) / 2);
    --section-padding: 116px;
  }

  @media only screen and (max-width: $media-breakpoint-1) {
    --margin: 34px;
    --section-padding: 44px;
  }

  // Desktop styling
  @media only screen and (min-width: $media-breakpoint-1) {
    &__hero {
      padding-top: calc(125px - var(--brand-navbar-height));
      padding-bottom: 70px;

      &__column {
        margin: 0 auto;
        max-width: 1604px;
        padding: 0 var(--margin);
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        overflow-x: hidden;
      }

      &__text {
        width: 480px;
        padding-top: 40px;

        .SignInButton {
          margin-top: 18px;
        }
      }

      &__image {
        margin-left: var(--margin);
        width: 480px;

        > div {
          width: 217.5%;
          > div {
            width: 100%;
            padding-bottom: 66.15%;
            background-image: url('../images/image_landing_page_hero.png');
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center center;
          }
        }
      }
    }

    &__section {
      padding: var(--section-padding) 0;

      &__column {
        margin: 0 auto;
        padding: 0 var(--margin);
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: center;
        overflow-x: hidden;
      }

      &__text {
        width: 480px;
        margin-right: var(--section-gap);
      }

      &__image {
        margin-left: var(--section-gap);
        width: 480px;

        > div > div {
          background-size: contain;
          background-position: left;
          background-repeat: no-repeat;
          width: 100%;
        }

        &--extensions > div > div {
          margin-left: -1.14%;
          padding-top: 98.6%;
          background-image: url('../images/image_landing_page_extensions.png');
        }

        &--lists > div {
          width: 153.8%;

          > div {
            padding-top: 77.7%;
            background-image: url('../images/image_landing_page_lists.png');
          }
        }

        &--search > div > div {
          padding-top: 79.1%;
          background-image: url('../images/image_landing_page_search.png');
        }

        &--archive > div > div {
          margin-left: -1.14%;
          padding-top: 98.6%;
          background-image: url('../images/image_landing_page_archive.png');
        }

        &--dark-mode > div {
          width: 162%;

          > div {
            padding-top: 78.7%;
            background-image: url('../images/image_landing_page_dark_mode.png');
          }
        }
      }

      &--alternate {
        background-color: var(--background-alt-color);

        .LandingPage__section {
          &__column {
            flex-direction: row-reverse;
          }

          &__text {
            margin-right: 0;
            margin-left: var(--section-gap);
          }

          &__image {
            margin-left: 0;
            margin-right: var(--section-gap);
          }
        }
      }
    }
  }

  // Mobile styling
  @media only screen and (max-width: $media-breakpoint-1) {
    &__hero {
      padding-top: calc(95px - var(--navbar-height-mobile));
      padding-bottom: 58px;

      &__column {
        padding: 0 var(--margin);
        display: flex;
        flex-direction: column;
        overflow-x: hidden;
      }

      &__text {
        .SignInButton {
          margin-top: 4px;
        }
      }

      &__image {
        margin-top: 40px;

        > div {
          width: 175%;
          > div {
            width: 100%;
            padding-bottom: 66.15%;
            background-image: url('../images/image_landing_page_hero.png');
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center center;
          }
        }
      }
    }

    &__section {
      padding: var(--section-padding) 0;

      &__column {
        padding: 0 var(--margin);
        display: flex;
        flex-direction: column;
        overflow-x: hidden;
      }

      &__image {
        // margin-top: 22px;

        > div > div {
          background-size: contain;
          background-position: left;
          background-repeat: no-repeat;
          width: 100%;
        }

        &--extensions > div > div {
          margin-left: -1.94%;
          padding-top: 98.6%;
          background-image: url('../images/image_landing_page_extensions.png');
        }

        &--lists > div {
          width: 207.8%;

          > div {
            padding-top: 77.7%;
            background-image: url('../images/image_landing_page_lists.png');
          }
        }

        &--search > div > div {
          padding-top: 79.1%;
          background-image: url('../images/image_landing_page_search.png');
        }

        &--archive > div > div {
          margin-left: -1.94%;
          padding-top: 98.6%;
          background-image: url('../images/image_landing_page_archive.png');
        }

        &--dark-mode > div {
          margin-left: -8%;
          width: 142%;

          > div {
            padding-top: 78.7%;
            background-image: url('../images/image_landing_page_dark_mode.png');
          }
        }
      }

      &--alternate {
        background-color: var(--background-alt-color);
      }
    }
  }
}
