@import '../base.scss';

.ArchivedPage {
  &__Navbar {
    &__timestamp {
      max-width: 656px;
      line-height: 140%;
      text-align: center;
      a { font-weight: 600; }
    }

    &__close-button {
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--text-light-gray);
      margin-left: 50px;
      cursor: pointer;
    }
  }

  &__content {
    position: absolute;
    top: var(--banner-navbar-height);
    left: 0;
    bottom: 0;
    right: 0;
    overflow: hidden;

    iframe {
      height: 100%;
      width: 100%;
    }
  }

  &--banner-hidden {
    .ArchivedPage__content { top: 0; }
  }

  .NotFound {
    z-index: 0;
  }
}