@import '../base.scss';

.Checkbox {
  display: flex;
  align-items: center;
  user-select: none;

  > label {
    padding-left: 10px;
  }

  &--right > label {
    padding-left: 0;
    padding-right: 10px;
  }

  > input {
    appearance: none;
    background-color: var(--background-color);
    border: var(--input-border-default);
    box-sizing: border-box;
    border-radius: 2px;
    padding: 8px;
    display: inline-block;
    position: relative;

    &:active {
      background-color: var(--text-light-light-gray);
    }

    &:checked:active {
      background-color: var(--dark-orange);
      border-color: var(--dark-orange);
      background-image: url('../images/icon_checkbox.svg');
      background-repeat: no-repeat;
      background-position: center;
    }

    &:checked {
      background-color: var(--orange);
      border: 1px solid var(--orange);
      color: white;
      background-image: url('../images/icon_checkbox.svg');
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  &--warning {
    &.Checkbox--checked > label {
      color: var(--red);
    }

    > input {
      &:checked:active {
        background-color: var(--dark-red);
        border-color: var(--dark-red);
      }
  
      &:checked {
        background-color: var(--red);
        border-color: var(--red);
      }
    }
  }
}