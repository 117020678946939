@import '../base.scss';

.Waitlist {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;

  &__logo {
    display: flex;
    height: 90px;
    width: 60px;
    align-items: center;
    justify-content: center;

    svg {
      color: var(--orange);
      transform: scale(1.8);
    }
  }
  
  a { 
    margin-top: 25px;
  }
  
  > * {
    margin-bottom: 10px;
  }
}