@import '../base.scss';

.Tag {
  @include app-column;
  
  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 18px;

    > * {
      box-sizing: border-box;
    }

    @media only screen and (max-width: 768px) {
      display: none;
    }
  }

  &__spacer {
    flex-grow: 10;
  }
}