@import '../base.scss';

.Pricing {
  @include brand;

  display: flex;
  justify-content: center;

  &__content {
    max-width: 100%;
    margin-top: calc(225px - var(--brand-navbar-height));
    margin-bottom: 166px;

    @media only screen and (max-width: $media-breakpoint-1) {
      margin-top: calc(104px - var(--navbar-height-mobile));
      margin-bottom: 42px;
    }
  }

  &__row {
    max-width: 1136px;
    padding: 0 74px;
    display: flex;
    flex-direction: row;
    justify-content: center;

    &--features {
      padding-top: 120px;
      flex-flow: wrap;
      justify-content: space-between;
    }

    @media only screen and (max-width: $media-breakpoint-2) {
      flex-direction: column;
      &--features {
        flex-direction: row;
        justify-content: space-between;
      }
    }

    @media only screen and (max-width: $media-breakpoint-1) {
      padding: 0 34px;

      &--features {
        padding-top: 65px;
      }
    }
  }

  &__left, &__right {
    width: 508px;

    @media only screen and (max-width: $media-breakpoint-2) {
      width: auto;
    }
  }

  &__spacer {
    width: 120px;
  }

  .PlanDescription {
    font-size: var(--font-size-medium);
    font-weight: 500;
    color: var(--orange);
    line-height: 26px;

    &--warning {
      display: flex;
      align-items: center;
      color: var(--red);
      font-weight: var(--font-weight-medium);
      line-height: 26px;
      svg { padding-right: 14px; }
      span { margin-top: 1px; flex-shrink: 10; }
    }

    @media only screen and (max-width: $media-breakpoint-1) {
      font-size: 14px;
      line-height: 22px;
      svg { transform: scale(0.70); padding-right: 6px; }
    }
  }

  .PlanButtons {
    display: flex;
    flex-direction: row;

    @media only screen and (max-width: $media-breakpoint-2) {
      margin-top: 55px;
    }

    @media only screen and (max-width: $media-breakpoint-1) {
      margin-top: 32px;
    }

    @media only screen and (max-width: 415px) {
      margin-top: 32px;
      flex-direction: column;
    }

    &__button {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 244px;
      min-height: 234px;
      border: 1px solid var(--text-light-gray);
      border-radius: 8px;
      text-align: center;
      cursor: default;
      padding-bottom: 20px;

      @media only screen and (max-width: $media-breakpoint-2) {
        width: auto;
        min-height: unset;
      }

      &--active {
        background-color: var(--background-alt-color);
      }

      &--clickable {
        cursor: pointer;
      }
  
      &__plan {
        font-size: var(--font-size-large);
        line-height: 22px;
        margin-top: 61px;
        margin-bottom: 14px;
        padding: 0 40px;

        @media only screen and (max-width: $media-breakpoint-2) {
          margin-top: 34px;
        }

        @media only screen and (max-width: $media-breakpoint-1) {
          font-size: 14px;
          margin-top: 26px;
          margin-bottom: 9px;
        }
      }
  
      &__price {
        font-size: var(--font-size-extra-large);
        font-weight: var(--font-weight-medium);
        line-height: 29px;
        margin-top: 0;
        margin-bottom: 14px;
        padding: 0 40px;

        @media only screen and (max-width: $media-breakpoint-1) {
          font-size: 18px;
          margin-bottom: 10px;
        }
      }
  
      &__features {
        font-size: var(--font-size-default);
        font-weight: var(--font-weight-light);
        line-height: 140%;
        color: var(--text-gray);
        margin-top: 0;
        padding: 0 40px;

        @media only screen and (max-width: $media-breakpoint-1) {
          font-size: 12px;
        }
      }

      &__spacer {
        flex-grow: 10;

        @media only screen and (max-width: $media-breakpoint-2) {
          min-height: 15px;
        }
      }

      &__action {
        margin-top: 53px;
        margin-left: 20px;
        margin-right: 20px;
        align-self: stretch;

        @media only screen and (max-width: $media-breakpoint-2) {
          margin-top: 44px;
        }

        @media only screen and (max-width: $media-breakpoint-1) {
          margin-top: 30px;
        }
      }
  
      &__upsell {
        font-size: var(--font-size-default);
        font-weight: var(--font-weight-bold);
        color: var(--orange);
        margin-top: 42px;
        margin-bottom: 0;

        @media only screen and (max-width: $media-breakpoint-2) {
          margin-top: 22px;
          margin-bottom: 0;
        }

        @media only screen and (max-width: $media-breakpoint-1) {
          font-size: 12px;
          margin-top: 22px;
          margin-bottom: 0;
        }
      }
  
      &:last-child {
        margin-left: 20px;

        @media only screen and (max-width: 415px) {
          margin-top: 20px;
          margin-left: 0;
        }
      }
    }
  }

  &__upsell {
    font-weight: 500;
    font-size: var(--font-size-medium);
    line-height: 160%;
    text-align: center;
    color: var(--orange);
    margin-top: 20px;
    margin-bottom: 0;
    margin-left: 64px;
    margin-right: 64px;

    @media only screen and (max-width: $media-breakpoint-1) {
      font-size: 14px;
      margin: 0;
      margin-top: 13px;
    }
  }

  &__feature {
    width: 20%;
    
    box-sizing: border-box;
    font-size: var(--font-size-medium);
    line-height: 26px;

    @media only screen and (max-width: $media-breakpoint-1) {
      min-width: 180px;
      padding-bottom: 28px;
    }

    &__header {
      font-weight: var(--font-weight-medium);
      margin-bottom: 11px;

      @media only screen and (max-width: $media-breakpoint-1) {
        font-size: 16px;
      }
    }

    &__text {
      font-weight: var(--font-weight-light);

      @media only screen and (max-width: $media-breakpoint-1) {
        font-size: 14px;
      }
    }
  }
}