@import '../base.scss';

.TagListRow {
  position: relative;
  border-bottom: var(--border-default);
  padding: 12px 6px 12px 12px;
  margin-bottom: 8px;
  margin-left: -12px;
  display: flex;
  align-items: center;

  @include hover-border;

  &:last-child {
    border-bottom: none;
  }

  &__content {
    flex-grow: 10;
    overflow: hidden;
  }

  &__header {
    display: flex;
    margin-bottom: 11px;
  }

  &__label {
    display: inline-block;
    color: var(--text-white);
    background-color: var(--orange);
    border: 2px solid var(--orange);
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
    font-size: var(--font-size-medium);
    font-weight: var(--font-weight-bold);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 4px 12px;
  }

  &__items-count {
    display: inline-block;
    color: var(--orange);
    border: 2px solid var(--orange);
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    background-color: var(--background-color);
    font-size: var(--font-size-medium);
    font-weight: var(--font-weight-bold);
    padding: 4px 12px;
  }

  &__text-container {
    overflow: hidden;
  }

  &__recent-item-title {
    font-size: var(--font-size-default);
    font-weight: var(--font-weight-light);
    line-height: 1.5;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__recent-item-footer {
    font-size: var(--font-size-default);
    font-weight: var(--font-weight-default);
    line-height: 1.5;
    color: var(--text-gray);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__TagControls {
    position: absolute;
    opacity: 0;
    transition: opacity 0.15s linear;
    top: 12px;
    right: 12px;
  }

  &:hover {
    .TagListRow__TagControls {
      opacity: 1;
    }
  }

  @media (hover: none) {
    .TagListRow__TagControls {
      display: none !important;
    }
  }
}
