@import '../base.scss';

.TokenInput {
  border: var(--input-border-default);
  box-sizing: border-box;
  border-radius: 2px;
  display: flex;
  align-items: center;
  overflow: hidden;
  padding-left: 3px;
  min-height: 39px;
  padding-top: 1.5px;
  padding-bottom: 1.5px;

  > .Token {
    margin-right: 3px;
    margin-top: 1.5px;
    margin-bottom: 1.5px;
  }

  > input {
    padding-left: 10px;
    padding-top: 8px;
    padding-bottom: 8px;
    flex-grow: 10;
    border: none;
  }

  &--wrap {
    flex-wrap: wrap;

    > input {
      min-width: 33%;
    }
  }

  &--has-tokens {
    > input {
      padding-left: 3px !important;
    }
  }
}