@import '../base.scss';

.DownloadAppButton {
  display: inline-block;
  color: var(--text-black);
  background-color: transparent;
  border: 1px solid var(--text-black);
  border-radius: 6px;
  padding: 4px 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &__svg--small { display: none; padding-top: 1px; }
  &__svg--large { display: inline-block; padding-top: 1px; }

  &--dark {
    color: var(--text-white);
    background-color: var(--text-black);
  }

  @media only screen and (max-width: $media-breakpoint-1) {
    &__svg--small { display: inline-block; }
    &__svg--large { display: none; }
  }
}