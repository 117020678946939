@import '../base.scss';

.BrowserExtension {
  width: 100%;
  padding: 22px 24px;
  box-sizing: border-box;
  overflow: hidden;
  background-color: var(--background-color);
  border: none;

  @include dialogs;

  &__message {
    padding: 20px 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    &__header {
      font-size: var(--font-size-large);
      font-weight: var(--font-weight-medium);
      margin-bottom: 13px;
    }
  
    &__body {
      margin-bottom: 15px;
      line-height: 21px;
      font-weight: var(--font-weight-light);
    }
  }

  &__text {
    font-size: var(--font-size-medium);
    font-weight: var(--font-weight-bold);
  }

  &__input {
    width: 100%;
  }

  &__header--saved {
    text-align: center;
    padding-top: 8px;
  }

  &__btn {
    margin-top: 10px;
  }
}