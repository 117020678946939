@import '../base.scss';

.NotFound {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: -1000;

  > div {
    text-align: center;
    max-width: 310px;
    color: var(--text-dark-gray);
  }

  &__header {
    font-size: var(--font-size-large);
    font-weight: var(--font-weight-medium);
    margin-bottom: 13px;
  }

  &__body {
    margin-bottom: 15px;
    line-height: 21px;
    font-weight: var(--font-weight-light);
  }
}