@import '../base.scss';

.ModalOverlay {
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 2000;

  display: relative;
  background-color: rgba(0, 0, 0, 0.3)
}

.Modal {
  position: relative;
  margin: 0 auto;
  top: 10%;
  display: block;
  width: 562px;

  overflow: hidden;
  
  background-color: var(--background-color);
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
  border: var(--border-default);
  border-radius: 4px;
  padding: 24px 28px;

  @media only screen and (max-width: 768px) {
    width: calc(100% - 30px);
  }

  @include dialogs;

  .TagSelect {
    width: 100%;
    flex-grow: 10;
  }
}