@import '../base.scss';

.Dropdown {
  display: block;
  position: relative;
  perspective: 600px;

  &__content {
    position: absolute;
    top: calc(100% + 10px);
    right: -10px;

    background-color: var(--background-color);
    box-sizing: border-box;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.08);
    border: var(--border-default);
    border-radius: 4px;
    padding: 24px 28px;

    transition: visibility 0s linear .25s, opacity .2s ease-out, transform .2s cubic-bezier(1, 0.22, 1, 0.19);
    visibility: hidden;
    opacity: 0;
    transform: translateZ(-40px) translateY(10px) rotateX(-10deg);
    transform-origin: -50% 10%;
  }

  @include dialogs;
}

.Dropdown--active {
  .Dropdown__content {
    transition: visibility 0s, opacity .2s ease-in, transform .4s cubic-bezier(0.19, 1, 0.22, 1);
    visibility: visible;
    opacity: 1;
    transform: none;
  }
}