@import '../base.scss';

@keyframes ToastTransition--in {
  from {
    opacity: 0;
    transform: translateY(100%);
  }

  50% {
    opacity: 1;
  }
}

.ToastTransition--in {
  animation-name: ToastTransition--in;
}

@keyframes ToastTransition--out {
  from {
    opacity: 1;
  }

  50% {
    opacity: 0;
    transform: translateY(100%);
  }

  to {
    opacity: 0;
  }
}

.ToastTransition--out {
  animation-name: ToastTransition--out;
}

.Toast {
  box-sizing: border-box;
  border-radius: 2px;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.25);
  font-size: var(--font-size-default);
  
  &--alert {
    margin: 4px;
    padding: 12px 40px;
    color: var(--text-white);
    background-color: var(--background-color-opposite);
    font-weight: var(--font-weight-medium);
    user-select: none;
    cursor: default;
  }

  &--dialog {
    display: flex;
    align-items: center;
    margin: 8px;
    width: 774px;
    padding: 15px 25px;
    background-color: var(--background-color);
    color: var(--text-black);
    font-weight: var(--font-weight-default);
    line-height: 140%;
    text-align: center;
    border: var(--border-default);
  }
}

.ToastBody {
  flex-grow: 1;
  .warning {
    color: var(--red);
    font-weight: var(--font-weight-medium);
  }
}

.ToastDialog {
  display: flex;
  align-items: center;
}

.ToastDialogBody {
  flex-grow: 10;
  margin: 10px 40px;
  margin-right: 60px;

  a { 
    color: var(--orange);
    font-weight: var(--font-weight-medium);
  }
}

.ToastDialogButtons {
  display: flex;
  flex-direction: column;

  > * { margin-bottom: 6px; min-width: 104px; }
  > *:last-child { margin-bottom: 0; }
}

.ToastContainer {
  z-index: 1000;
  position: fixed;
  padding: 27px;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
}