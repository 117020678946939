@import '../base.scss';

.SignInOptions {
  @include brand;

  position: relative;
  height: 100%;

  > .Navbar {
    z-index: 100;
  }

  &__content {
    position: absolute;
    top: var(--brand-navbar-height);
    left: 0;
    bottom: var(--brand-navbar-height);;
    right: 0;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    > h2 {
      margin-bottom: 48px;
    }

    > .btn {
      width: 300px;
      margin-bottom: 12px;

      @media only screen and (max-width: $media-breakpoint-1) {
        width: 280px !important;
      }
    }

    > p {
      margin-top: 26px;
      max-width: 277px;
      font-weight: var(--font-weight-light);
      font-size: var(--font-size-small);
      line-height: 170%;
      text-align: center;
    }
  }
}
