@import '../base.scss';

.AddItemDropdown {
  perspective-origin: -200px 0;
  &__trigger {
    height: 38px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: var(--text-light-light-gray);
    stroke-width: 2px;
    transition: color 0.15s linear;

    &:hover, &--active {
      color: var(--orange);
    }
  }

  &__content {
    width: 400px;
  }

  .TagSelect {
    flex-grow: 10;
  }
}