@import '../base.scss';

.SearchBar {
  position: relative;
  $height: 38px;
  height: $height;
  display: flex;
  flex-flow: nowrap;
  align-items: center;
  border: var(--border-default);
  border-radius: $height / 2;
  padding-right: 10px;
  z-index: 1000;
  perspective: 600px;

  // We include hover-border, but override search box specific things
  @include hover-border-small;
  &:hover, &--hover-border-small { 
    border-radius: $height / 2;
  }

  &__icon {
    color: var(--text-light-gray);
    height: 16px;
    width: 16px;
    margin-left: 15px;
    margin-right: 8px;
  }

  &__cancel {
    color: var(--text-light-gray);
    margin-left: 5px;
    margin-right: 2px;
    cursor: pointer;
    opacity: 0;
    &--show {
      opacity: 1;
    }
  }

  &__input {
    width: 132px;
    flex-grow: 10;
    padding-left: 0 !important;
    border: none !important;
    transition: width .6s cubic-bezier(0.19, 1, 0.22, 1);

    input {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    &--expanded {
      width: 332px;
    }
  }

  &__dropdown {
    position: absolute;
    top: calc(100% + 10px);
    right: 5px;
    width: 390px;
    background-color: var(--background-color);
    box-sizing: border-box;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    border: var(--border-default);
    padding: 22px;
    padding-top: 0;
    box-sizing: border-box;
    transition: visibility 0s linear .2s, opacity .2s ease-out, transform .25s cubic-bezier(1, 0.22, 1, 0.19);
    visibility: hidden;
    opacity: 0;
    transform: translateZ(-50px) translateY(25px) rotateX(-20deg);
    transform-origin: 50% 10%;

    &--visible {
      transition: visibility 0s, opacity .2s ease-in .1s, transform .6s cubic-bezier(0.19, 1, 0.22, 1);
      visibility: visible;
      opacity: 1;
      transform: none;
    }

    &__header {
      font-weight: var(--font-weight-medium);
      font-size: var(--font-size-default);
      line-height: 19px;
      color: var(--text-gray);
      margin-top: 18px;
      margin-bottom: 10px;
    }

    &__recentSearch {
      font-weight: var(--font-weight-medium);
      font-size: var(--font-size-default);
      line-height: 19px;
      margin-bottom: 8px;
      cursor: pointer;
    }
  }
}