@import '../base.scss';

.ItemListRow {
  position: relative;
  border-bottom: var(--border-default);
  padding: 12px 12px 12px 12px;
  margin-left: -12px;
  margin-bottom: 8px;
  display: flex;
  align-items: flex-start;

  @include hover-border;

  &:last-child {
    border-bottom: none;
  }

  &__image-container {
    position: relative;
    background-color: var(--text-light-light-light-gray);
    min-width: 52px;
    min-height: 52px;
    border-radius: 2px;
    overflow: hidden;

    > .loading { color: var(--text-light-gray); }
  }

  &__image {
    background-color: var(--text-light-light-light-gray);
    width: 52px;
    height: 52px;

    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__text-container {
    flex-grow: 10;
    margin-left: 13px;
    overflow: hidden;
  }

  &__title {
    font-size: var(--font-size-medium);
    font-weight: var(--font-weight-medium);
    line-height: 1.2;
    margin-bottom: 6px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__description {
    font-size: var(--font-size-default);
    font-weight: var(--font-weight-light);
    line-height: 1.5;
    margin-bottom: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__footer {
    font-size: var(--font-size-default);
    font-weight: var(--font-weight-default);
    line-height: 1.5;
    color: var(--text-gray);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__note-container {
    border: var(--input-border-default);
    border-radius: 2px;
    padding: 9px 11px;
    margin-top: 8px;
  }

  &__note {
    color: var(--text-black);
    font-weight: var(--font-weight-light);
    font-style: italic;
    line-height: 22px;
    overflow: hidden; // Required by react-line-ellipsis
  }

  &__ItemControls {
    position: absolute;
    opacity: 0;
    transition: opacity 0.15s linear;
    top: 12px;
    right: 12px;
  }

  &:hover {
    .ItemListRow__title, .ItemListRow__description { padding-right: 130px; }
    .ItemListRow__ItemControls {
      opacity: 1;
    }
  }

  @media (hover: none) {
    .ItemListRow__ItemControls {
      display: none !important;
    }
  }
}
