@import '../base.scss';

.WhatIsDumpsterPopover {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 18px 28px 28px 28px;

  border-top: var(--border-default);
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.08);
  background-color: var(--background-color);

  @media only screen and (min-width: $media-breakpoint-1) {
    display: none;
  }

  &__hide {
    position: absolute;
    top: 18px;
    right: 16px;
    cursor: pointer;
  }

  &__text {
    text-align: center;
    font-weight: var(--font-weight-light);
    line-height: 24px;
    margin-top: 6px;
    margin-bottom: 18px;
  }
}