@import '../base.scss';

.StripePaymentModal--stripe-loading {
  min-height: 205px;
}

.StripeElement {
  @include input;
}

.StripeElement--invalid {
  border-color: var(--red);
}
