@import '../base.scss';

.Brand { 
  position: relative;
  z-index: 0;
  height: 100%;
  display: flex;
  flex-direction: column;

  &__content {
    flex: 1 0 auto;
  }

  &--menu-visible {
    overflow-y: hidden;
  }
}