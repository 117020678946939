@import '../base.scss';

:root {
  --tag-color-hover: var(--dark-orange);
  --tag-color-default: var(--orange);
  --tag-color-selectable: var(--light-orange);

  --type-color-hover: var(--dark-gray);
  --type-color-default: var(--gray);
  --type-color-selectable: var(--light-gray);

  --source-color-hover: var(--dark-gray);
  --source-color-default: var(--gray);
  --source-color-selectable: var(--light-gray);

  @media (prefers-color-scheme: dark) {
    --tag-color-hover: var(--light-orange);
    --tag-color-default: var(--orange);
    --tag-color-selectable: var(--dark-orange);

    --type-color-hover: var(--light-gray);
    --type-color-default: var(--gray);
    --type-color-selectable: var(--dark-gray);

    --source-color-hover: var(--light-gray);
    --source-color-default: var(--gray);
    --source-color-selectable: var(--dark-gray);
  }
}

.Tokens {
  display: flex;
  flex-wrap: wrap;
  margin-right: -6px;

  .Token {
    width: 47%;
    max-width: fit-content;
    flex-grow: 1;
    margin-top: 6px;
    margin-right: 6px;
  }
}

.Token {
  display: flex;
  box-sizing: border-box;
  height: fit-content;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-right: 8px;
  padding-left: 14px;
  border-radius: 2px;
  background-color: var(--text-gray);
  transition: background-color 0.15s linear;
  cursor: default;

  &__label {
    flex-grow: 10;
    box-sizing: border-box;
    padding-right: 6px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: var(--text-white);
    font-weight: var(--font-weight-medium);
  }

  &__remove {
    height: 15px;
    width: 15px;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 15px;
    margin-left: 2px;
    border-radius: 7.5px;
    color: var(--text-white);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &--clickable {
    cursor: pointer;
  }
  
  &--source { 
    background-color: var(--source-color-default);
    .Token__remove { background-color: var(--dark-gray); }
  }
  &--source--hover { background-color: var(--source-color-default); }
  &--source--hover:hover { background-color: var(--source-color-hover); }
  &--source--selectable { background-color: var(--source-color-selectable); }
  &--source--selectable--hover:hover { background-color: var(--source-color-default); }

  &--type {
    background-color: var(--type-color-default);
    .Token__remove { background-color: var(--dark-gray); }
  }
  &--type--hover { background-color: var(--type-color-default); }
  &--type--hover:hover { background-color: var(--type-color-hover); }
  &--type--selectable { background-color: var(--type-color-selectable); }
  &--type--selectable--hover:hover { background-color: var(--type-color-default); }

  &--tag { 
    background-color: var(--tag-color-default);
    .Token__remove { background-color: var(--dark-orange); }
  }
  &--tag--hover { background-color: var(--tag-color-default); }
  &--tag--hover:hover { background-color: var(--tag-color-hover); }
  &--tag--selectable { background-color: var(--tag-color-selectable); }
  &--tag--selectable--hover:hover { background-color: var(--tag-color-default); }
  &--tag--new {
    position: relative;
    padding-left: 30px;
    svg {
      left: 8px;
      top: 8px;
      position: absolute;
      padding-right: 6px;
    }
  }
}
