@import '../base.scss';

.Install {
  @include brand;

  &__section {
    margin: 0 74px;
    margin-top: calc(225px - var(--brand-navbar-height));
    text-align: center;

    p {
      margin: 0 auto;
      max-width: 815px;
    }

    @media only screen and (max-width: $media-breakpoint-1) {
      margin: 0 34px;
      margin-top: calc(104px - var(--navbar-height-mobile));
    }
  }

  &__buttons {
    margin-top: 33px;
    > * { margin: 14px; }
    display: flex;
    flex-direction: row;
    justify-content: center;

    @media only screen and (max-width: $media-breakpoint-1) {
      margin-top: 20px;
      > * { margin: 8px; }
    }

    @media only screen and (max-width: $media-breakpoint-0) {
      flex-direction: column;
      align-items: center;
    }
  }

  &__image {
    margin: 0 auto;
    margin-top: 92px;
    padding-bottom: 106px;
    max-width: 1094px;

    > div {
      padding-top: 68.8%;
      background-image: url('../images/image_install_apps.png');
      background-size: contain;
    }

    @media only screen and (max-width: $media-breakpoint-1) {
      margin-top: 47px;
      padding-bottom: 89px;
    }
  }

  &--onboarding {
    .Install {
      &__section {
        margin-top: calc(163px - var(--app-navbar-height));
      }

      &__image {
        margin-top: 40px;
        max-width: 638px;
      }
    }
  }
}
