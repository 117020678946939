@import '../base.scss';

.ReadyToTryDumpster {
  @include brand;
  padding: 121px 74px;
  border-top: var(--border-default);
  text-align: center;

  &--no-border {
    border-top: 0;
  }

  p {
    margin: 0 auto;
    max-width: 780px;
  }

  .GetStartedButton {
    margin-top: 38px;
  }

  @media only screen and (max-width: $media-breakpoint-1) {
    padding: 44px 34px;
    .GetStartedButton { margin-top: 22px; }
  }
}