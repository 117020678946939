@import '../base.scss';

$trigger-diameter: 38px;

.AccountDropdown {
  height: $trigger-diameter;
  perspective-origin: -130px 0;
  
  &__trigger {
    width: $trigger-diameter;
    height: $trigger-diameter;
    border-radius: $trigger-diameter / 2;
    overflow: hidden;
    padding: 0;
    box-sizing: border-box;
  }

  &__trigger-avatar {
    width: $trigger-diameter;
    height: $trigger-diameter;
    border-radius: $trigger-diameter / 2;

    @include hover-border-small;
    &:hover, &--hover-border-small { 
      box-shadow: 0 0 0 4px var(--border-color);
      border-radius: $trigger-diameter / 2;
    }
  }

  &__trigger-avatar-default {
    width: $trigger-diameter;
    height: $trigger-diameter;
    border-radius: $trigger-diameter / 2;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--text-light-light-gray);
    transition: color 0.15s linear;

    &:hover, &--active {
      color: var(--orange);
    }
  }

  &__content {
    width: 266px;
  }

  &__avatar {
    width: 56px;
    height: 56px;
    border-radius: 28px;
    margin-bottom: 20px;

    &--default {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--text-light-light-light-gray);
      color: var(--text-light-gray);
    }
  }

  &__counts {
    margin-bottom: 20px;
    color: var(--text-light-gray);
    font-weight: var(--font-weight-medium);

    > span {
      margin-right: 24px;
    }
  } 

  &__display-name {
    font-weight: var(--font-weight-medium);
    line-height: 1.2;
    margin-bottom: 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__email {
    font-size: var(--font-size-small);
    line-height: 1.2;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__upgrade-button {
    margin-top: 14px;
    font-weight: var(--font-weight-medium) !important;
  }

  &__premium-indicator {
    display: inline-block;
    color: var(--orange);
    font-weight: var(--font-weight-medium);
    margin-top: 12px;
  }

  &__divider {
    padding: 26px 0;

    > div {
      position: absolute;
      left: 0;
      right: 0;
      border-bottom: var(--border-default);
    }
  }

  &__link {
    display: block;
    font-weight: var(--font-weight-medium);
    cursor: pointer;
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }

    &--warning {
      color: var(--red);
    }
  }
}
