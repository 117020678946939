@import '../base.scss';

.Help {
  @include brand;

  h2 {
    margin-bottom: 36px;

    @media only screen and (max-width: $media-breakpoint-1) {
      margin-left: 12px;
    }
  }

  h3 {
    margin-top: 36px;

    @media only screen and (max-width: $media-breakpoint-1) {
      margin-left: 12px;
    }
  }

  &__content {
    margin: 0 auto;
    margin-top: calc(225px - var(--brand-navbar-height));
    padding: 0 74px;
    padding-bottom: 134px;
    max-width: 1136px;

    @media only screen and (max-width: $media-breakpoint-1) {
      padding: 0 8px;
    }

    @media only screen and (max-width: $media-breakpoint-1) {
      margin-top: calc(104px - var(--navbar-height-mobile));
      padding-bottom: 42px;
    }
  }
}

.HelpSection {
  @include brand;

  border: var(--input-border-default);
  border-radius: 4px;
  margin-bottom: 8px;

  transition-property: background-color;
  transition-duration: 2000ms;
  transition-timing-function: linear;

  &__title {
    font-weight: 500;
    padding: 16px 24px;
    display: flex;
    align-items: center;
    cursor: pointer;

    &__spacer {
      flex-grow: 10;
    }
  }

  &__content {
    display: none;
    padding: 16px 24px;
    padding-top: 0;

    p, li {
      font-size: var(--font-size-default);
      font-weight: var(--font-weight-light);
      line-height: 156.14%;
      margin-bottom: 6px;

      a {
        font-weight: var(--font-weight-medium);
      }
    }

    ul {
      margin-bottom: 6px;
      margin-top: 8px;
    }

    p:last-child, ul:last-child {
      margin-bottom: 0;
    }
  }

  &--expanded {
    .HelpSection {
      &__content {
        display: block;
      }
    }
  }

  &--highlighted {
    transition: none;
    background-color: var(--background-strong-alt-color);
  }
}