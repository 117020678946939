@import '../base.scss';

.NavTabs {
  box-sizing: border-box;
  list-style-type: none;
  margin: 0;
  padding: 0;
  padding-top: 6px;
  height: 34px;

  &__item {
   display: inline-block;
   padding-right: 50px;
  }

  &__item:last-child {
    padding-right: 0;
  }

  &__link {
    display: inline-block;
    font-weight: var(--font-weight-default);
    padding-bottom: 8px;
    border-bottom: 3px solid var(--background-color);
    transition: border-bottom-color 0.15s linear;

    &:hover {
      border-bottom: 3px solid var(--orange);
    }
  }

  &__link--active {
    font-weight: var(--font-weight-medium);
    border-bottom: 3px solid var(--orange);
  }
}