@import '../base.scss';

.TagGridCard {
  position: relative;
  box-sizing: border-box;
  border-radius: 4px;
  border: var(--border-default);
  width: 340px;

  @media only screen and (max-width: $media-breakpoint-1) {
    width: 100%;
  }

  @include hover-border;

  &__header {
    display: flex;
    margin-bottom: 4px;
    padding: 20px 24px 0 24px;
  }

  &__label {
    display: inline-block;
    color: var(--text-white);
    background-color: var(--orange);
    border: 2px solid var(--orange);
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
    font-size: var(--font-size-medium);
    font-weight: var(--font-weight-bold);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 4px 12px;
  }

  &__items-count {
    display: inline-block;
    color: var(--orange);
    border: 2px solid var(--orange);
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    background-color: var(--background-color);
    font-size: var(--font-size-medium);
    font-weight: var(--font-weight-bold);
    padding: 4px 12px;
  }

  &__image-carousel {
    padding: 0 24px;
    padding-top: 8px;
    padding-bottom: 14px;

    &__image-container {
      display: inline-block;
      background-color: var(--text-light-light-light-gray);
      height: 65px;
      width: 65px;
      margin-right: 10px;
      overflow: hidden;

      &:last-child {
        margin-right: 0;
      }
    }

    &__image {
      width: 100%;
      height: 100%;
      
      > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  &__recent-item-timestamp {
    display: block;
    font-weight: var(--font-weight-default);
    font-size: var(--font-size-small);
    color: var(--text-gray);
    padding: 0 24px;
    padding-bottom: 20px;
  }

  &__TagControls {
    position: absolute;
    opacity: 0;
    transition: opacity 0.15s linear;
    bottom: 12px;
    right: 12px;
  }
  
  &:hover {
    .TagGridCard__TagControls {
      opacity: 1;
    }
  }

  @media (hover: none) {
    .TagGridCard__TagControls {
      display: none !important;
    }
  }
}
