@import '../base.scss';

.ItemGridCard {
  position: relative;
  border-radius: 4px;
  border: var(--border-default);
  padding: 0;
  width: 340px;
  box-sizing: border-box;
  
  @include hover-border;

  @media only screen and (max-width: $media-breakpoint-1) {
    width: 100%;
  }

  &__link {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 0;
  }

  &__title {
    color: var(--text-black);
    font-size: var(--font-size-large);
    font-weight: var(--font-weight-bold);
    line-height: 24px;
    margin: 20px 24px;
    margin-bottom: 12px;
    overflow: hidden; // Required by react-line-ellipsis
  }

  &__description {
    color: var(--text-black);
    font-weight: var(--font-weight-light);
    line-height: 22px;
    margin: 0 24px;
    margin-bottom: 12px;
    overflow: hidden; // Required by react-line-ellipsis
  }

  &__image-container {
    position: relative;
    z-index: -10;
    background-color: var(--text-light-light-light-gray);
    height: 100px;
    margin: 0 24px;
    margin-bottom: 6px;
    overflow: hidden;
  }

  &__image {
    background-color: var(--text-light-light-light-gray);
    width: 100%;
    height: 100%;

    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__Loading {
    color: var(--text-light-gray);
  }

  &__Tokens {
    position: relative;
    margin: 0 24px;
    z-index: 1000;
  }

  &__note-container {
    border: var(--input-border-default);
    border-radius: 2px;
    margin: 0 24px;
    padding: 12px 14px;
    margin-top: 10px;
    margin-bottom: 16px;
  }

  &__note {
    color: var(--text-black);
    font-weight: var(--font-weight-light);
    font-style: italic;
    line-height: 22px;
    overflow: hidden; // Required by react-line-ellipsis
  }

  &__timestamp {
    font-weight: var(--font-weight-default);
    font-size: var(--font-size-small);
    color: var(--text-gray);
    line-height: 15px;
    margin: 0 24px;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  &__ItemControls {
    position: absolute;
    opacity: 0;
    transition: opacity 0.15s linear;
    bottom: 12px;
    right: 12px;
  }
  
  &:hover {
    .ItemGridCard__ItemControls {
      opacity: 1;
    }
  }

  @media (hover: none) {
    .ItemGridCard__ItemControls {
      display: none !important;
    }
  }
}
