@import '../base.scss';

$media-breakpoint: $media-breakpoint-1;

.NavbarBrandHeader {
  display: flex;
  align-items: center;

  &__logo {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--orange);
    height: 38px;
    width: 23px;
    margin-right: 12px;
    margin-bottom: 2px;

    @media only screen and (max-width: $media-breakpoint) {
      transform: scale(0.8);
      margin-left: 8px;
      margin-bottom: 3px;
      margin-right: 9px;
    }

    &--large {
      transform: scale(1.1);
      margin-bottom: 1px;

      @media only screen and (max-width: $media-breakpoint) {
        transform: scale(0.9);
        margin-left: 8px;
        margin-bottom: 3px;
        margin-right: 9px;
      }
    }    
  }

  &__title {
    font-size: var(--font-size-large);
    font-weight: var(--font-weight-bold);
    color: var(--text-black);
    font-family: var(--font-family-brand);
    text-decoration: none;

    @media only screen and (max-width: $media-breakpoint) {
      font-size: var(--font-size-medium);
      margin-right: 12px;
    }

    &--large {
      font-size: var(--font-size-extra-large);

      @media only screen and (max-width: $media-breakpoint) {
        font-size: var(--font-size-large);
      }
    }

    &--hide-on-mobile {
      @media only screen and (max-width: $media-breakpoint) { display: none; }
    }
  }
}

.NavbarTitleHeader {
  display: flex;
  align-items: center;
  position: relative;

  &__chevron {
    height: 38px;
    width: 23px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
    cursor: pointer;
  }

  &__avatar {
    height: 24px;
    width: 24px;
    border-radius: 12px;
    margin-right: 12px;
    margin-bottom: 2px;

    @media only screen and (max-width: $media-breakpoint) {
      display: none;
    }
  }

  &__title {
    font-size: var(--font-size-large);
    font-weight: var(--font-weight-bold);

    @media only screen and (max-width: $media-breakpoint) {
      font-size: var(--font-size-medium);
    }

    &__prepend {
      font-weight: 500;
    }
  }

  &__subtitle {
    font-weight: var(--font-weight-medium);
    font-size: var(--font-size-small);
    color: var(--text-light-gray);
    margin-top: 2px;
  }

  &__extras {
    margin-left: 14px;
  }
}

.NavbarSpacer {
  flex-grow: 10;
}

.NavbarDivider {
  margin-left: 14px;
  margin-right: 44px;

  > div {
    height: 40px;
    border-right: var(--border-default);
  }

  @media only screen and (max-width: $media-breakpoint) {
    display: none;
  }
}

.NavbarMenuTrigger {
  padding-right: 14px;
  padding-bottom: 4px;
  display: none;

  @media only screen and (max-width: $media-breakpoint) {
    display: block;
  }
}

.NavbarLink {
  &--active {
    font-weight: var(--font-weight-medium);
  }
}

.Navbar {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;

  > .NavbarLink {
    @media only screen and (max-width: $media-breakpoint) {
      display: none;
    }
  }

  &--app {
    padding: 21px 24px;
    height: var(--app-navbar-height);
    border-bottom: var(--border-default);
    background-color: var(--background-color);

    @media only screen and (max-width: $media-breakpoint) {
      height: var(--navbar-height-mobile);
      padding: 10px 15px;
      padding-bottom: 5px;
    }
  }

  &--brand {
    padding: 44px 54px;
    height: var(--brand-navbar-height);
    background-color: var(--background-color);

    @media only screen and (max-width: $media-breakpoint) {
      height: var(--navbar-height-mobile);
      padding: 9px 9px;
      padding-bottom: 5px;
    }
  }

  &--banner {
    padding: 34px 42px;
    height: var(--banner-navbar-height);
    border-bottom: var(--border-default);
    background-color: var(--background-color);

    @media only screen and (max-width: $media-breakpoint) {
      height: var(--navbar-height-mobile);
      padding: 9px 9px;
      padding-bottom: 5px;
    }
  }

  &--footer {
    padding: 44px 54px;
    height: var(--brand-footer-height);
    background-color: var(--background-alt-color);

    @media only screen and (max-width: $media-breakpoint) {
      height: auto;
      min-height: var(--footer-height-mobile);
      flex-flow: column;
      align-items: flex-start;
      padding: 25px 9px;

      > .NavbarBrandHeader {
        order: 1;
        padding-top: 25px;
        padding-bottom: 5px;
      }

      > .NavbarLink {
        display: block;
        padding-left: 8px;
        padding-right: 18px;
        padding-top: 16px;
      }
    }
  }

  &--menu {
    position: absolute;
    top: var(--navbar-height-mobile);
    left: 0;
    bottom: 0;
    right: 0;

    .NavbarBrandHeader {
      display: none;
    }

    .NavbarSpacer {
      display: none;
    }
  }

  .--hide-on-mobile {
    @media only screen and (max-width: $media-breakpoint) { display: none; }
  }

  > * {
    box-sizing: border-box;
    margin-right: 28px;

    @media only screen and (max-width: $media-breakpoint) {
      margin-right: 8px;
    }
  }

  > *:last-child {
    margin-right: 0;
  }
}
