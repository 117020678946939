@import '../base.scss';

.App { 
  position: relative;
  z-index: 0;
  height: 100%;

  &__navbar {
    position: fixed;
    top: 0;
    z-index: 100;
    width: 100%;
  }

  &__content {
    padding-top: var(--app-navbar-height);

    @media only screen and (max-width: $media-breakpoint-1) {
      padding-top: var(--navbar-height-mobile);
    }
  }
}